export const initialState = {
    selectedFAQ: null,
    visibleValue: null
};

export const ACTIONS = {
    SELECT_FAQ: "select_faq",
    DELETE_FAQ: "delete_faq",
    SET_VISIBLE_VALUE: "set_visible_value",
    DELETE_VISIBLE_VALUE: "delete_visible_value"
}

// Selectors (functions return result with state)

export const reducer = (state, action) => {
    switch (action.type) {
        case "select_faq":
            return {
                ...state,
                selectedFAQ: action.payload.id
            }
        case "delete_faq": {
            return {
                ...state,
                selectedFAQ: null
            }
        }
        case "set_visible_value":
            return {
                ...state,
                visibleValue: action.payload.id
            }
        case "delete_visible_value": {
            return {
                ...state,
                visibleValue: null
            }
        }
        default:
            return state
    }
}

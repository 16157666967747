import React from "react"
import LocationBlockCell from "./LocationBlockCell"
import delaJasse from "../../images/locationBlock/lajasse.png"
import montLobre from "../../images/locationBlock/montlobre.png"
import vignes from "../../images/locationBlock/deux-soleils.png"
import delaJassePurple from "../../images/locationBlock/lajasse_purple.png"
import montLobrePurple from "../../images/locationBlock/montlobre_purple.png"
import vignesPurple from "../../images/locationBlock/deux-soleils_purple.png"

function LocationBlock({ data }) {
  let { items } = data
  // order items
  items.sort((a, b) => a.ordre - b.ordre)
  return (
    <div className="location-block container">
      <div className="location-block-container">
        <div className="row">
          <div className="col-lg-2 col-md-12"></div>
          <div className="col-lg-10 col-md-12">
            <div className="location-block-header sticky">
              <div className="row no-gutters">
                <div className="col-lg-4 col-md-12">
                  <img
                    src={delaJasse}
                    alt="domaine de la jasse"
                    className="mx-auto d-block img-fluid p-1"
                  />
                </div>
                <div className="col-lg-4 col-md-12">
                  <img
                    src={montLobre}
                    alt="domaine montlobre"
                    className="mx-auto d-block img-fluid p-1"
                  />
                </div>
                <div className="col-lg-4 col-md-12">
                  <img
                    src={vignes}
                    alt="domaine des deux soleils"
                    className="mx-auto d-block img-fluid p-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {items.map(item => (
          <div key={`location-block-item-${item.id}`} className="row">
            <div className="col-lg-2 col-md-12">
              <div className="location-block-item-location">
                <span>{item.location}</span>
              </div>
            </div>
            <div className="col-lg-10 col-md-12">
              <div className="location-block-item-tab">
                <div className="row no-gutters">
                  {item.fullWidth ? (
                    <div className="col-lg-12">
                      <LocationBlockCell
                        title={item.title1}
                        description={item.description1}
                        mobileImages={[delaJassePurple, montLobrePurple, vignesPurple]}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="col-xl-4 col-lg-12">
                        <LocationBlockCell
                          title={item.title1}
                          description={item.description1}
                          className="br"
                          mobileImages={[delaJassePurple]}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-12">
                        <LocationBlockCell
                          title={item.title2}
                          description={item.description2}
                          className="br"
                          mobileImages={[montLobrePurple]}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-12">
                        <LocationBlockCell
                          title={item.title3}
                          description={item.description3}
                          mobileImages={[vignesPurple]}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LocationBlock

import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"

function StoryBlock({ data, media }) {
  let { items } = data
  // order items
  items.sort((a, b) => a.ordre - b.ordre)
  return (
    <div className="story-block container">
      {items.map(item => (
        <div key={`story-block-item-${item.id}`} className="story-block-item">
          <span className="story-block-item-date purple">{item.date}</span>
          <div
            className="story-block-item-description content"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
          <div className="story-block-item-images">
            <div className="story-block-item-image">
              {item.image1 && (
                <AbstractMedia
                  media={media}
                  abstractMedia={item.image1}
                  title={item.legend1 || ""}
                  className="story-block-item-image-wrapper"
                />
              )}
              <span className="text-right">{item.legend1}</span>
            </div>
            <div className="story-block-item-image">
              {item.image2 && (
                <AbstractMedia
                  media={media}
                  abstractMedia={item.image2}
                  title={item.legend2 || ""}
                  className="story-block-item-image-wrapper"
                />
              )}
              <span className="text-right">{item.legend2}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default StoryBlock

import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import Title from "../../components/Title"
import downloadIcon from "../../images/downloadBlock/telechargement.svg"
import vivinoIcon from "../../images/downloadBlock/vivino.png"
import arrow from "../../images/arrows/long-purple-arrow.png"
import { getBackgroundColorClass } from "../../utils/enumHelper"

const DownloadBlock = ({ data, media, lang }) => {
  const backgroundColorClass = getBackgroundColorClass(data["couleur de fond"])
  return (
      <div className="download-block container">
        <div
            className={`row ${
                data?.position === "droite"
                    ? "justify-content-end"
                    : "justify-content-start"
            } no-gutters`}
        >
          <div className="col-md-auto">
            <div className="download-block-image">
              {data?.image && (
                <AbstractMedia
                  abstractMedia={data.image}
                  media={media}
                  className="download-block-image-image"
                  objectPosition="right center"
                />
              )}
            </div>
          </div>
          <div className={`col-lg-6 col-md-12${backgroundColorClass}`}>
            <div className="download-block-text">
              <div className="download-block-text-container">
                <Title
                    className="download-block-title"
                    balise={data["balise du titre"]}
                    dangerouslySetInnerHTML={{ __html: data?.titre }}
                />
                <div
                    className="download-block-description content"
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              </div>
              <a
                  href={
                    data["type de bloc"] === "Avis"
                        ? data.lien
                        : media[data?.fichier?.id]
                  }
                  className="download-block-download"
                  download={data["type de bloc"] === "Avis" ? null : true}
                  target={data["type de bloc"] === "Avis" ? "_blank" : null}
                  rel={
                    data["type de bloc"] === "Avis" ? "noopener noreferrer" : null
                  }
              >
                <img
                    src={
                      data["type de bloc"] === "Avis" ? vivinoIcon : downloadIcon
                    }
                    className="picto"
                    alt={
                      data["type de bloc"] === "Avis"
                          ? "picto vivino"
                          : "picto download"
                    }
                />
                <div className="d-flex align-items-center">
                  <img src={arrow} className="arrow-picto" alt="arrow picto" />
                  <span className="purple">{data["titre du lien"]}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default DownloadBlock

import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import Title from "../../components/Title"
import arrow from "../../images/arrows/long-purple-arrow.png"

const ImageTextBlock = ({ data, media, lang }) => {
  return (
      <div
          className={`image-text-block container-fluid${
              data["position image"] === "Droite" ? " reverse" : ""
          }`}
      >
        <div className="image-text-block-container">
          {data["image"] && (
            <div className="image-text-block-image">
              <AbstractMedia media={media} abstractMedia={data["image"]} />
            </div>
          )}
          <div className="image-text-block-text-container">
            <div className={`image-text-block-text-container-wrapper${data["ajouter la largeur maximale"] === "Oui" ? " max-width-on" : ""}`}>
              {data["balise du titre"] && data["balise du titre"] !== "" && (
                  <Title balise={data["balise du titre"]} className="purple">
                    {data.titre}
                  </Title>
              )}
              {(data["image texte"] || data["texte image"]) && (
                  <div className="image-text-block-icon-container">
                    <div className="image-text-block-text-image">
                      {data["image texte"] && (
                          <AbstractMedia
                              media={media}
                              abstractMedia={data["image texte"]}
                          />
                      )}
                    </div>
                    <div
                        className="image-text-block-image-text content"
                        dangerouslySetInnerHTML={{ __html: data["texte image"] }}
                    />
                  </div>
              )}
              {data.texte && data.texte !== "" && (
                  <div
                      className={`image-text-block-text content ${
                          data["liste avec fleches"] === "Oui" ? " arrow-list" : ""
                      }`}
                      dangerouslySetInnerHTML={{ __html: data.texte }}
                  />
              )}
              {data.lien && (
                  <a
                      href={`/${lang}${data.lien}`}
                      className="image-text-block-link"
                  >
                    <img src={arrow} alt="arrow" />
                    <span className="purple">
                  {lang === "fr" ? "EN SAVOIR +" : "FIND OUT MORE"}
                </span>
                  </a>
              )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default ImageTextBlock

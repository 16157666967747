import React from "react"
import facebook from "../images/navbar/facebook.png"
import instagram from "../images/navbar/instagram.png"
import linkedin from "../images/navbar/linkedin.png"
import twitter from "../images/navbar/twitter.png"
import purpleFacebook from "../images/navbar/purple-facebook.png"
import purpleInstagram from "../images/navbar/purple-instagram.png"
import purpleTwitter from "../images/navbar/purple-twitter.png"
import purpleWeb from "../images/navbar/purple-web.png"

function SocialNetworks({ message, isVineBlock, link }) {
  return (
    <div className={`social-networks${isVineBlock ? " vine" : ""}`}>
      <span>{message}</span>
      <a
        className="social-network"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/blbvignobles/"
      >
        <img src={isVineBlock ? purpleFacebook : facebook} alt="facebook icon" />
        <span>facebook</span>
      </a>
      <a
        className="social-network"
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/blb_vignobles/"
      >
        <img src={isVineBlock ? purpleTwitter : twitter} alt="twitter icon" />
        <span>twitter</span>
      </a>
      {!isVineBlock && (
        <a
          className="social-network"
          target="_blank"
          rel="noopener noreferrer"
          href="https://linkedin.com/company/blbvignobles/"
        >
          <img src={linkedin} alt="linkedin icon" />
          <span>linkedin</span>
        </a>
      )}
      <a
        className="social-network"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/blb_vignobles/"
      >
        <img src={isVineBlock ? purpleInstagram : instagram} alt="instagram icon" />
        <span>instagram</span>
      </a>
      {link && link !== "" && (
        <a
          className="social-network"
          target="_blank"
          rel="noopener noreferrer"
          href={link}
        >
          <img src={purpleWeb} alt="site web" />
          <span>{link}</span>
        </a>
      )}
    </div>
  )
}

export default SocialNetworks

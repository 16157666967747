import React from "react"

function LocationBlockCase({ title, description, className, mobileImages }) {
  return (
    <div
      className={`location-block-cell ${className ? className : ""}${
        title === "" && description === "" ? " empty" : ""
      }`}
    >
      <span className="location-block-cell-title purple">{title}</span>
      <div
        className="location-block-cell-description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="location-block-cell-images">
        {mobileImages &&
          mobileImages.map((mobileImage, i) => (
            <img
              key={`mobile-image-${i}`}
              src={mobileImage}
              alt={title}
            />
          ))}
      </div>
    </div>
  )
}

export default LocationBlockCase

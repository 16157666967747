export const getColorClass = (color, isBackground) => {
    switch (color) {
        case "WHITE":
            return isBackground ? "bg-white" : "white"
        case "PURPLE":
            return isBackground ? "bg-purple" : "purple"
        case "GREY":
            return isBackground ? "bg-grey" : "grey"
        case "GREEN":
            return isBackground ? "bg-green" : "green"
        case "LIGHT_GREEN":
            return isBackground ? "bg-light-green" : "light-green"
        case "BLACK":
            return isBackground ? "bg-black" : "black"
        default:
            return ""
    }
}

export const getBackgroundColorClass = color => {
    switch (color) {
        case "Fonce":
            return " bg-darker"
        case "Intermediaire":
            return " bg-intermediary"
        default:
            return " bg-clear"
    }
}
import React from "react"

function Title({ children, className, balise, dangerouslySetInnerHTML }) {
    let title = null
    switch (balise) {
        case "H1":
            title = dangerouslySetInnerHTML ? (
                <h1 className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML}></h1>
            ) : (
                    <h1 className={className}>{children}</h1>
                )
            break
        case "H2":
            title = dangerouslySetInnerHTML ? (
                <h2 className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML}></h2>
            ) : (
                    <h2 className={className}>{children}</h2>
                )
            break
        case "H3":
            title = dangerouslySetInnerHTML ? (
                <h3 className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML}></h3>
            ) : (
                    <h3 className={className}>{children}</h3>
                )
            break
        case "H4":
            title = dangerouslySetInnerHTML ? (
                <h4 className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML}></h4>
            ) : (
                    <h4 className={className}>{children}</h4>
                )
            break
        case "H5":
            title = dangerouslySetInnerHTML ? (
                <h5 className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML}></h5>
            ) : (
                    <h5 className={className}>{children}</h5>
                )
            break
        default:
            title = dangerouslySetInnerHTML ? (
                <h6 className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML}></h6>
            ) : (
                    <h6 className={className}>{children}</h6>
                )
            break
    }
    return title
}

export default Title

import React from 'react'
import { getColorClass } from "../../utils/enumHelper"
import Title from "../../components/Title"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"

function PictoBlock({ data, media }) {
    let { title, baliseTitle, textColor, backgroundColor, items } = data
    // order items
    items.sort((a, b) => a.ordre - b.ordre)
    const backgroundColorClass = getColorClass(backgroundColor, true)
    const textColorClass = getColorClass(textColor, false)

    return (
        <div className={`picto-block block ${backgroundColorClass} ${textColorClass}`}>
            <div className="container-fluid">
                {title?.length > 0 && (
                    <div className="picto-block-title">
                        <Title balise={baliseTitle} className="picto-block-title">{title}</Title>
                    </div>
                )}
                <div className="row">
                    {items.map(item => (
                        <div key={`picto-block-${item.id}`} className="col-lg-3 col-md-6">
                            <div className="picto-block-item">
                                {item.image &&
                                    <AbstractMedia
                                        media={media}
                                        abstractMedia={item.image}
                                        title={title + " image " + item.id}
                                        className="picto-block-item-image"
                                    />
                                }
                                <div className="picto-block-item-description" dangerouslySetInnerHTML={{ __html: item.description }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PictoBlock

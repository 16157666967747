import React from 'react';
import AbstractMedia from "../../components/abstractMedia/AbstractMedia";
import arrow from "../../images/arrows/long-purple-arrow.png"

const PictoTitleBlock = ({ data, media }) => {
    return (
        <div className="picto-title-block">
            <div className="container">
                <h2 className="purple">{data.titre}</h2>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className={`picto-title-block-container${data["position picto 1"] === "Bas" ? " reverse" : ""}`}>
                            {data["picto 1"] &&
                            <AbstractMedia
                                media={media}
                                abstractMedia={data["picto 1"]}
                                className="picto-title-block-image"
                            />
                            }
                            <div className="picto-title-block-container-title">
                                <img src={arrow} alt="arrow" />
                                <span className="purple" dangerouslySetInnerHTML={{ __html: data["titre picto 1"] }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className={`picto-title-block-container${data["position picto 2"] === "Bas" ? " reverse" : ""}`}>
                            {data["picto 2"] &&
                            <AbstractMedia
                                media={media}
                                abstractMedia={data["picto 2"]}
                                className="picto-title-block-image"
                            />
                            }
                            <div className="picto-title-block-container-title">
                                <img src={arrow} alt="arrow" />
                                <span className="purple" dangerouslySetInnerHTML={{ __html: data["titre picto 2"] }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className={`picto-title-block-container${data["position picto 3"] === "Bas" ? " reverse" : ""}`}>
                            {data["picto 3"] &&
                            <AbstractMedia
                                media={media}
                                abstractMedia={data["picto 3"]}
                                className="picto-title-block-image"
                            />
                            }
                            <div className="picto-title-block-container-title">
                                <img src={arrow} alt="arrow" />
                                <span className="purple" dangerouslySetInnerHTML={{ __html: data["titre picto 3"] }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className={`picto-title-block-container${data["position picto 4"] === "Bas" ? " reverse" : ""}`}>
                            {data["picto 4"] &&
                            <AbstractMedia
                                media={media}
                                abstractMedia={data["picto 4"]}
                                className="picto-title-block-image"
                            />
                            }
                            <div className="picto-title-block-container-title">
                                <img src={arrow} alt="arrow" />
                                <span className="purple" dangerouslySetInnerHTML={{ __html: data["titre picto 4"] }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PictoTitleBlock;

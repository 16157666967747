import React, { createContext, useContext, useReducer } from "react"

// prepare the data layer
export const StateContext = createContext(null);

// wrap ou app and provide the data layer
export const StateProvider = ({ reducer, initialState, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {children}
        </StateContext.Provider>
    )
}

// pull information from the data layer
export const useStateValue = () => useContext(StateContext);

import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import SocialNetworks from "../../components/SocialNetworks"

const VineBlock = ({ data, media, lang }) => {
  return (
      <div
          className={`vine-block container${
              data["position image 1"] === "Droite" ? " reverse" : ""
          }`}
      >
        <div className="vine-block-container">
          {data["image 1"] && (
              <AbstractMedia
                  media={media}
                  abstractMedia={data["image 1"]}
                  className="vine-block-container-image-1"
              />
          )}
          {(data["petite image"] ||
              (data["texte 1"] && data["text 1"] !== "")) && (
              <div className="vine-block-container-image-text">
                {data["petite image"] && (
                    <AbstractMedia
                        media={media}
                        abstractMedia={data["petite image"]}
                        className="vine-block-container-little-image center-image"
                    />
                )}
                {data["texte 1"] && data["texte 1"] !== "" && (
                    <div
                        className={`vine-block-container-image-text-text ${
                            data["position image 1"] === "Droite" ? "reverse " : ""
                        }content purple bg-light-green`}
                        dangerouslySetInnerHTML={{ __html: data["texte 1"] }}
                    />
                )}
              </div>
          )}
        </div>
        {(data["image 2"] || (data["texte 2"] && data["text 2"] !== "")) && (
            <div className="vine-block-container">
              {data["texte 2"] && data["texte 2"] !== "" && (
                  <div
                      className="vine-block-text content"
                      dangerouslySetInnerHTML={{ __html: data["texte 2"] }}
                  />
              )}
              {data["image 2"] && (
                  <AbstractMedia
                      media={media}
                      abstractMedia={data["image 2"]}
                      className={`vine-block-container-image-2${
                          !data["texte 2"] || data["texte 2"] === "" ? " w-100" : ""
                      }`}
                  />
              )}
            </div>
        )}
        {(data["image 3"] || (data["texte 3"] && data["text 3"] !== "")) && (
            <div className="vine-block-container">
              {data["image 3"] && (
                  <AbstractMedia
                      media={media}
                      abstractMedia={data["image 3"]}
                      className={`vine-block-container-image-3${
                          !data["texte 3"] || data["texte 3"] === "" ? " w-100" : ""
                      }`}
                  />
              )}
              {data["texte 3"] && data["texte 3"] !== "" && (
                  <div
                      className="vine-block-text content"
                      dangerouslySetInnerHTML={{ __html: data["texte 3"] }}
                  />
              )}
            </div>
        )}
        <SocialNetworks
            message={lang === "fr" ? "RETROUVEZ-NOUS SUR" : "FOLLOW US"}
            isVineBlock
            link={data.lien}
        />
      </div>
  )
}

export default VineBlock

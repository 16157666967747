import React, { useMemo } from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"

const TeamBlock = ({ data, media, lang }) => {
    const getBackgroundColorClass = color => {
        switch (color) {
            case "Fonce":
                return " bg-darker"
            case "Intermediaire":
                return " bg-intermediary"
            default:
                return " bg-clear"
        }
    }

    const backgroundColorClass1 = getBackgroundColorClass(data["couleur 1"])
    const backgroundColorClass2 = getBackgroundColorClass(data["couleur 2"])
    const backgroundColorClass3 = getBackgroundColorClass(data["couleur 3"])

    const recruitmentBlock = useMemo(() => {
        return lang === "fr" ? (
            <div className="team-block-container-recruitment bg-purple content white">
                <h2>Recrutement</h2>
                <div>
                    Rejoignez une entreprise familiale responsable à taille humaine et aux
                    valeurs durables : exigence, respect, plaisir et partage. Découvrez
                    les derniers postes à pourvoir chez BLB Vignobles basé à Combaillaux,
                    au Nord de Montpellier (34). Le recrutement des saisonniers a
                    uniquement lieu en mai-juin, lors du relevage des vignes et des
                    derniers travaux avant vendanges.
                </div>
                <div className="team-block-container-recruitment-contact">
                    Contact : info@blb-vignobles.com / +33 4 67 67 04 04
                </div>
            </div>
        ) : (
            <div className="team-block-container-recruitment bg-purple content white">
                <h2>Recruitment</h2>
                <div>
                    Join a responsible, family-run business human and sustainable values:
                    high standards, respect, pleasure, etc. and sharing. Discover the
                    latest job openings at BLB Vineyards based in Combaillaux, north of
                    Montpellier (34). The Recruitment of seasonal workers only takes place
                    in May-June, during the lifting of the vines and the last works before
                    grape harvest.
                </div>
                <div className="team-block-container-recruitment-contact">
                    Contact : info@blb-vignobles.com / +33 4 67 67 04 04
                </div>
            </div>
        )
    }, [lang])

    return (
        <div className="team-block container">
            <div className="row">
                <div className="col-lg-4 col-md-12">
                    <div className="team-block-container">
                        {data["image 1"] && (
                            <AbstractMedia media={media} abstractMedia={data["image 1"]} />
                        )}
                        <div
                            className={`team-block-container-frame content${backgroundColorClass1}`}
                        >
              <span className="team-block-container-name purple">
                {data["nom 1"]}
              </span>
                            <span className="team-block-container-job">
                {data["profession 1"]}
              </span>
                        </div>
                    </div>
                </div>
                {data["ajouter bloc recrutement"] === "Oui" ? (
                    <div className="col-lg-8 col-md-12">
                        <div className="team-block-container">{recruitmentBlock}</div>
                    </div>
                ) : (
                    <>
                        <div className="col-lg-4 col-md-12">
                            <div className="team-block-container">
                                {data["image 2"] && (
                                    <AbstractMedia
                                        media={media}
                                        abstractMedia={data["image 2"]}
                                    />
                                )}
                                <div
                                    className={`team-block-container-frame content${backgroundColorClass2}`}
                                >
                  <span className="team-block-container-name purple">
                    {data["nom 2"]}
                  </span>
                                    <span className="team-block-container-job">
                    {data["profession 2"]}
                  </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="team-block-container">
                                {data["image 3"] && (
                                    <AbstractMedia
                                        media={media}
                                        abstractMedia={data["image 3"]}
                                    />
                                )}
                                <div
                                    className={`team-block-container-frame content${backgroundColorClass3}`}
                                >
                  <span className="team-block-container-name purple">
                    {data["nom 3"]}
                  </span>
                                    <span className="team-block-container-job">
                    {data["profession 3"]}
                  </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default TeamBlock

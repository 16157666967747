import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"

library.add(far, fas);

const Icon = props => {
    const { icon } = props
    return (
        <FontAwesomeIcon icon={[`${icon.lib}`, `${icon.className}`]} />
    )
}

export default Icon

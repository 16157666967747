import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const Image = ({ media, image, className, objectPosition, title }) => {
  const srcImage = media ? media[image.id] : null
  const alt = title ? title : image.image.name.split(".").slice(0, -1).join(".")

  return image.optimization === "FIXED" ? (
    <Img
      className={className}
      fixed={srcImage}
      objectFit="contain"
      objectPosition={objectPosition}
      alt={alt}
    />
  ) : (
    <Img
      className={className}
      fluid={srcImage}
      objectFit="contain"
      objectPosition={objectPosition}
      alt={alt}
    />
  )
}

export default Image

import React, { useState, useEffect } from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import Title from "../../components/Title"

const TitleBlock = ({ data, media }) => {
  const [hasRan, setHasRan] = useState(false)
  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  })

  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    if (!hasRan) {
      setHasRan(true)
      updateScreenSize()
    }
    window.addEventListener("resize", updateScreenSize)
    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [screenSize, hasRan])

  return (
    <div className="title-block">
      <div className="container-fluid">
        <div className="title-block-container">
         { screenSize.width > 1200 ? (data.image && (<AbstractMedia
            abstractMedia={data.image}
            media={media}
            title={data.titre}
          />))
         : (data["image mobile"] && (<AbstractMedia
            abstractMedia={data["image mobile"]}
            media={media}
            title={data.titre}
          />))}

          {data.block || (
            <Title balise={data["balise du titre"]} className="container">
              {data.titre}
            </Title>
          )}
        </div>
      </div>
    </div>
  )
}

export default TitleBlock

import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import Title from "../../components/Title"
import arrow from "../../images/arrows/long-purple-arrow.png"
import { getColorClass } from "../../utils/enumHelper"

const ThreeImagesBlock = ({ data, media, lang }) => {
  return (
      <div
          className={`three-images-block container ${data["bloc flottant"] === "Oui" ? "floating" : ""
          }`}
      >
        <Title
            balise={data["balise du titre"]}
            className={getColorClass(data["couleur du titre"], false)}
        >
          {data.titre}
        </Title>
        <div className="row no-gutters-sm">
          <div className="col-lg-4 col-md-12">
            <div className="bg-white">
              <div className="three-images-block-container">
                <div className="three-images-block-image">
                  {data["image 1"] && (
                      <AbstractMedia
                          media={media}
                          className="center-image"
                          abstractMedia={data["image 1"]}
                      />
                  )}
                </div>
                <div
                    className="three-images-block-image-description content"
                    dangerouslySetInnerHTML={{ __html: data["description 1"] }}
                />
                {data["lien 1"] && (
                    <a href={data["lien externe"] === "Oui" ? data["lien 1"] : `/${lang}${data["lien 1"]}`}
                       target={data["lien externe"] === "Oui" ? "_blank" : null}
                       rel={data["lien externe"] === "Oui" ? "noopener noreferrer" : null}>
                      <img src={arrow} alt="arrow" />
                      <span className="purple">{data["titre du lien 1"]}</span>
                    </a>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="bg-white">
              <div className="three-images-block-container">
                <div className="three-images-block-image">
                  {data["image 2"] && (
                      <AbstractMedia
                          media={media}
                          className="center-image"
                          abstractMedia={data["image 2"]}
                      />
                  )}
                </div>
                <div
                    className="three-images-block-image-description content"
                    dangerouslySetInnerHTML={{ __html: data["description 2"] }}
                />
                {data["lien 2"] && (
                    <a href={data["lien externe"] === "Oui" ? data["lien 2"] : `/${lang}${data["lien 2"]}`}
                       target={data["lien externe"] === "Oui" ? "_blank" : null}
                       rel={data["lien externe"] === "Oui" ? "noopener noreferrer" : null}>
                      <img src={arrow} alt="arrow" />
                      <span className="purple">{data["titre du lien 2"]}</span>
                    </a>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="bg-white">
              <div className="three-images-block-container">
                <div className="three-images-block-image">
                  {data["image 3"] && (
                      <AbstractMedia
                          media={media}
                          className="center-image"
                          abstractMedia={data["image 3"]}
                      />
                  )}
                </div>
                <div
                    className="three-images-block-image-description content"
                    dangerouslySetInnerHTML={{ __html: data["description 3"] }}
                />
                {data["lien 3"] && (
                    <a href={data["lien externe"] === "Oui" ? data["lien 3"] : `/${lang}${data["lien 3"]}`}
                       target={data["lien externe"] === "Oui" ? "_blank" : null}
                       rel={data["lien externe"] === "Oui" ? "noopener noreferrer" : null}>
                      <img src={arrow} alt="arrow" />
                      <span className="purple">{data["titre du lien 3"]}</span>
                    </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ThreeImagesBlock

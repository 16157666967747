import React from "react"

import SimpleBlock from "./SimpleBlock"
import LocationBlock from "./LocationBlock"
import PictoBlock from "./PictoBlock"
import StoryBlock from "./StoryBlock"

import PictoTitleBlock from './PictoTitleBlock';
import DownloadBlock from './DownloadBlock';
import ImageTextBlock from './ImageTextBlock';
import TeamBlock from './TeamBlock';
import FaqBlock from './FaqBlock';
import ContactBlock from './ContactBlock';
import AxeBlock from './AxeBlock';
import PartnerBlock from './PartnerBlock';
import ValueBlock from './ValueBlock';
import VineBlock from './VineBlock';
import ThreeImagesBlock from './ThreeImagesBlock';
import TitleBlock from './TitleBlock';


const Blocks = ({ blocks, media, lang }) => (
    <div className="blocks">
        {(blocks.map(block => {
            if (!block) return <div></div>
            switch (block.__typename) {
                case "jhipster_SimpleBlockDTO":
                    return <SimpleBlock key={block.id} data={block} media={media} />
                case "jhipster_LocationBlockDTO":
                  return <LocationBlock key={block.id} data={block} media={media} />
                case "jhipster_StoryBlockDTO":
                  return <StoryBlock key={block.id} data={block} media={media} />
                case "jhipster_PictoBlockDTO":
                  return <PictoBlock key={block.id} data={block} media={media} />
                    /* ADD FIXED BLOCK HERE
                        inside the src\resouces\templates\react
                        of java IO-CMS project
                    */
                case "jhipster_CustomBlockDTO":
                    switch(block.subType) {
                        
                        case "PictoTitleBlock":
                  return <PictoTitleBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></PictoTitleBlock>
                        case "DownloadBlock":
                  return <DownloadBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></DownloadBlock>
                        case "ImageTextBlock":
                  return <ImageTextBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></ImageTextBlock>
                        case "TeamBlock":
                  return <TeamBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></TeamBlock>
                        case "FaqBlock":
                  return <FaqBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></FaqBlock>
                        case "ContactBlock":
                  return <ContactBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></ContactBlock>
                        case "AxeBlock":
                  return <AxeBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></AxeBlock>
                        case "PartnerBlock":
                  return <PartnerBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></PartnerBlock>
                        case "ValueBlock":
                  return <ValueBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></ValueBlock>
                        case "VineBlock":
                  return <VineBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></VineBlock>
                        case "ThreeImagesBlock":
                  return <ThreeImagesBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></ThreeImagesBlock>
                        case "TitleBlock":
                  return <TitleBlock key={block.id} data={block.values} media={media} lang={lang} id={block.id}></TitleBlock>
                        
                        default:
                        return <div key={block.id}></div>
                    }
                default:
                    return <div key={block.id}></div>
            }
        }))}
    </div>
)

export default Blocks

import React from "react"
import arrow from "../../images/arrows/long-purple-arrow.png"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"

function PartnerBlock({ data, media, lang }) {
  return (
      <div className="partner-block container">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="partner-block-container">
              <div className="partner-block-image">
                {data["image 1"] && (
                    <AbstractMedia
                        abstractMedia={data["image 1"]}
                        media={media}
                        title={data["titre 1"]}
                        className="partner-block-image-image"
                    />
                )}
              </div>
              <h2>{data["titre 1"]}</h2>
              <div
                  className="partner-block-description purple"
                  dangerouslySetInnerHTML={{ __html: data["description 1"] }}
              />
              {data["lien 1"] && data["lien 1"] !== "" && (
                  <a href={data["lien 1"]} className="purple" target="_blank" rel="noopener">
                    <img src={arrow} alt="" />
                    <span>{lang === "fr" ? "Consulter le rapport RSE" : "View the CSR Report"}</span>
                  </a>
              )}
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="partner-block-container">
              <div className="partner-block-image">
                {data["image 2"] && (
                    <AbstractMedia
                        abstractMedia={data["image 2"]}
                        media={media}
                        title={data["titre 2"]}
                        className="partner-block-image-image"
                    />
                )}
              </div>
              <h2>{data["titre 2"]}</h2>
              <div
                  className="partner-block-description purple"
                  dangerouslySetInnerHTML={{ __html: data["description 2"] }}
              />
              {data["lien 2"] && data["lien 2"] !== "" && (
                  <a href={data["lien 2"]} className="purple" target="_blank" rel="noopener">
                    <img src={arrow} alt="" />
                    <span>{lang === "fr" ? "Consulter le rapport RSE" : "View the CSR Report"}</span>
                  </a>
              )}
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="partner-block-container">
              <div className="partner-block-image">
                {data["image 3"] && (
                    <AbstractMedia
                        abstractMedia={data["image 3"]}
                        media={media}
                        title={data["titre 3"]}
                        className="partner-block-image-image"
                    />
                )}
              </div>
              <h2>{data["titre 3"]}</h2>
              <div
                  className="partner-block-description purple"
                  dangerouslySetInnerHTML={{ __html: data["description 3"] }}
              />
              {data["lien 3"] && data["lien 3"] !== "" && (
                  <a href={data["lien 3"]} className="purple" target="_blank" rel="noopener">
                    <img src={arrow} alt="" />
                    <span>{lang === "fr" ? "Consulter le rapport RSE" : "View the CSR Report"}</span>
                  </a>
              )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default PartnerBlock

import React, { useEffect } from "react"
import purpleArrow from "../../images/arrows/purple-arrow.png"
import { ACTIONS } from "../../utils/reducer"
import { useStateValue } from "../../utils/StateProvider"

const FaqBlock = ({ data, id }) => {
  const { state, dispatch } = useStateValue()

  useEffect(() => {
    if (data["ouvert par defaut"] === "Oui") {
      console.log(id)
      dispatch({ type: ACTIONS.SELECT_FAQ, payload: { id: id } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeFaq = () => {
    if (state.selectedFAQ === id) {
      dispatch({ type: ACTIONS.DELETE_FAQ })
    } else {
      dispatch({ type: ACTIONS.SELECT_FAQ, payload: { id: id } })
    }
  }

  return (
      <div
          className={`faq-block container${
              state.selectedFAQ === id ? " selected" : ""
          }`}
      >
        <div
            aria-hidden="true"
            className="faq-block-container"
            onClick={changeFaq}
        >
          <img src={purpleArrow} alt="arrow" className="arrow" />
          <div className="faq-block-container-text">
            <h2>{data?.titre}</h2>
            <div
                className="faq-block-description content"
                dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </div>
        </div>
      </div>
  )
}

export default FaqBlock

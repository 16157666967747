import React from "react"

function SimpleBlock(props) {
  return (
    <div className="simple-block container">
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: props.data.content }}
      />
    </div>
  )
}

export default SimpleBlock

import React, { useState } from "react"
import tractor from "../../images/contactBlock/contact_tracteur.jpg"
import blb from "../../images/navbar/BLB-logo-entier.png"
import locationIcon from "../../images/contactBlock/picto_localisation.svg"
import { Formik } from "formik"
import axios from "axios"

function ContactBlock({ lang }) {
  const [message, setMessage] = useState({
    isError: null,
    message: null
  })
  const sendContact = (values, { resetForm }) => {
    values.locale = lang ? lang.toUpperCase() : "FR"
    axios
        .get(process.env.GATSBY_CMS_URL_GET_TOKEN)
        .then(res => {
          const token = res.data.id_token
          axios
              .post(process.env.GATSBY_CMS_URL_SEND_CONTACT, values, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then(res => {
                if (res.status === 200) {
                  setMessage({
                    isError: false,
                    message: lang === "fr"
                        ? "Votre message a été transmis avec succès à nos services, nous reviendrons vers vous au plus vite !"
                        : "Your message has been successfully transmitted to our services, we will get back to you as soon as possible!"
                  })
                  resetForm({
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    message: "",
                  })
                } else {
                  setMessage({
                    isError: true,
                    message: lang === "fr"
                        ? "Un erreur est survenue lors de l'envoi du formulaire. Veuillez nous excuser pour la gêne occasionnée."
                        : "An error occurred while sending the form. We apologize for the inconvenience."
                  })
                }
              })
              .catch(err => {
                setMessage({
                  isError: true,
                  message: lang === "fr"
                      ? "Un erreur est survenue lors de l'envoi du formulaire. Veuillez nous excuser pour la gêne occasionnée."
                      : "An error occurred while sending the form. We apologize for the inconvenience."
                })
              })
        })
        .catch(err => {
          setMessage({
            isError: true,
            message: lang === "fr"
                ? "Un erreur est survenue lors de l'envoi du formulaire. Veuillez nous excuser pour la gêne occasionnée."
                : "An error occurred while sending the form. We apologize for the inconvenience."
          })
        })
  }

  return (
      <div className="contact-block container">
        <div className="contact-block-container">
          <div className="contact-block-wrapper">
            <div className="contact-block-wrapper-icon">
              <div className="contact-block-wrapper-frame">
                <img
                    src={locationIcon}
                    alt="Téléchargement icône"
                    width="61"
                    height="100"
                />
                <div className="contact-block-wrapper-frame-text">
                  <h2>Maison Le Breton</h2>
                  <div className="contact-block-wrapper-frame-text-text">
                    <span>SARL BLB Vignobles – SCEA Vignes Des Deux Soleils</span>
                    <span>Domaine de la Jasse</span>
                    <span>F-34980 Combaillaux</span>
                    <span>+33 4 67 67 04 04</span>
                    <a href="mailto:info@maison-le-breton.com">
                      info@maison-le-breton.com
                    </a>
                    <a
                        href="www.boutiquedelajasse-montlobre.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      www.boutiquedelajasse-montlobre.com
                    </a>
                  </div>
                </div>
              </div>
              <img src={blb} className="logo-blb-vignobles" alt="BLB Vignobles" />
            </div>
            <div className="contact-block-wrapper-image">
              <img src={tractor} alt="tracteur" />
            </div>
          </div>
          <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                message: "",
              }}
              validate={values => {
                const errors = {}
                if (!values.lastname || values.lastname === "") {
                  errors.lastname = "Required"
                }
                if (!values.firstname || values.firstname === "") {
                  errors.firstname = "Required"
                }
                if (!values.email || values.email === "") {
                  errors.email = "Required"
                } else if (
                    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                        values.email
                    )
                ) {
                  errors.email = "Bad format"
                }
                if (!values.message || values.message === "") {
                  errors.message = "Required"
                }
                return errors
              }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={sendContact}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <div
                              className={`my-form-group${
                                  errors.lastname ? " error" : ""
                              }`}
                          >
                            <label htmlFor="field_lastname">
                              {lang === "fr" ? "Nom*" : "Last name*"}
                            </label>
                            <input
                                id="field_lastname"
                                className="my-form-control"
                                type="text"
                                name="lastname"
                                onChange={handleChange}
                                value={values.lastname}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div
                              className={`my-form-group${
                                  errors.firstname ? " error" : ""
                              }`}
                          >
                            <label htmlFor="field_firstname">
                              {lang === "fr" ? "Prénom*" : "First name*"}
                            </label>
                            <input
                                id="field_firstname"
                                className="my-form-control"
                                type="text"
                                name="firstname"
                                onChange={handleChange}
                                value={values.firstname}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                              className={`my-form-group${
                                  errors.email ? " error" : ""
                              }`}
                          >
                            <label htmlFor="field_email">
                              {lang === "fr" ? "Adresse email*" : "Email address*"}
                            </label>
                            <input
                                id="field_email"
                                className="my-form-control"
                                type="text"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                              className={`my-form-group${
                                  errors.phone ? " error" : ""
                              }`}
                          >
                            <label htmlFor="field_phone">
                              {lang === "fr" ? "Téléphone" : "Phone"}
                            </label>
                            <input
                                id="field_phone"
                                className="my-form-control"
                                type="text"
                                name="phone"
                                onChange={handleChange}
                                value={values.phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div
                          className={`my-form-group${errors.message ? " error" : ""}`}
                      >
                        <label htmlFor="field_message">Message*</label>
                        <textarea
                            id="field_message"
                            rows="7"
                            className="my-form-control"
                            name="message"
                            onChange={handleChange}
                            value={values.message}
                        ></textarea>
                      </div>
                      <span className="required_fields">{lang === "fr" ? "* Champs obligatoires" : "* Required fields"}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-end flex-column">
                    <button type="submit">
                      {lang === "fr" ? "Envoyer" : "Send"}
                    </button>
                    <div
                        className={`message${
                            Object.keys(errors).length > 0 || message?.isError ? " error" : ""
                        }`}
                    >
                      {Object.keys(errors).length > 0
                          ? lang === "fr"
                              ? "Vous devez remplir tous les champs obligatoires pour valider l'envoie du formulaire"
                              : "You must fill in all the required fields to validate the sending of the form"
                          : message.message}
                    </div>
                  </div>
                </form>
            )}
          </Formik>
        </div>
      </div>
  )
}

export default ContactBlock

import React from "react"
import Icon from "./Icon"
import Image from "./Image"

const AbstractMedia = ({
  media,
  abstractMedia,
  className,
  title,
  objectPosition,
}) => (
  <>
    {abstractMedia && abstractMedia.icon && (
      <Icon icon={abstractMedia.icon} className={className} />
    )}
    {abstractMedia && abstractMedia.image && (
      <Image
        image={abstractMedia}
        media={media}
        className={className}
        title={title}
        objectPosition={objectPosition}
      />
    )}
  </>
)

export default AbstractMedia

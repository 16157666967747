import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import { getBackgroundColorClass } from "../../utils/enumHelper"
import { ACTIONS } from "../../utils/reducer"
import { useStateValue } from "../../utils/StateProvider"

const ValueBlock = ({ data, media }) => {
  const { state, dispatch } = useStateValue()

  const handleChange = isVisible => {
    if (state.visibleValue === data.titre && isVisible === false) {
      dispatch({ type: ACTIONS.DELETE_VISIBLE_VALUE })
    }
    if (state.visibleValue !== data.titre && isVisible) {
      dispatch({ type: ACTIONS.SET_VISIBLE_VALUE, payload: { id: data.titre } })
    }
  }

  const backgroundColorClass = getBackgroundColorClass(data["couleur de fond"])

  return (
      <div id={data.titre} className="value-block container">
        <div className="value-block-container">
          <div className="value-block-wrapper">
            {data.image && (
                <AbstractMedia
                    media={media}
                    abstractMedia={data.image}
                    title={data.title}
                    className="value-block-image"
                />
            )}
            <VisibilitySensor partialVisibility onChange={handleChange}>
              <div className={`value-block-frame${backgroundColorClass}`}>
                {data.picto && (
                    <AbstractMedia
                        media={media}
                        abstractMedia={data.picto}
                        title={data.titre}
                    />
                )}
                <div className="value-block-text">
                  <h2 className="purple">{data.titre}</h2>
                  <div
                      className="value-block-description"
                      dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </div>
              </div>
            </VisibilitySensor>
          </div>
        </div>
      </div>
  )
}

export default ValueBlock

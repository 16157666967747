import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import arrow from "../../images/arrows/axe-arrow.png"

const AxeBlock = ({ data, media, lang }) => {
  const axeBlockFrameJSX = (
      <div
          className={
            data["ajouter cadre"] && data["ajouter cadre"] === "Non"
                ? "axe-block-without-frame"
                : "axe-block-frame"
          }
      >
        {data["ajouter cadre"] && data["ajouter cadre"] !== "Non" && <img src={arrow} alt="arrow" />}
        {data["ajouter cadre"] && data["ajouter cadre"] !== "Non" && (
            <span className="axe-block-frame-title purple">
          {lang === "fr"
              ? "Pour améliorer, aller plus loin"
              : "To improve, go further"}
        </span>
        )}
        <div
            className="axe-block-frame-text content"
            dangerouslySetInnerHTML={{ __html: data["cadre texte"] }}
        />
      </div>
  )

  return (
      <div className="axe-block container">
        {data.titre && data.titre !== "" && (
            <h2 className="purple">{data.titre}</h2>
        )}
        {data["cadre toute la largeur"] &&
        data["cadre toute la largeur"] === "Oui" &&
        axeBlockFrameJSX}
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="axe-block-container">
              {data["picto"] && (
                  <AbstractMedia
                      abstractMedia={data["picto"]}
                      media={media}
                      className="axe-block-picto"
                  />
              )}
              <div
                  className="axe-block-text content"
                  dangerouslySetInnerHTML={{ __html: data["texte"] }}
              />
              {data["position cadre"] &&
              data["position cadre"] !== "" &&
              data["position cadre"] !== data["position images"] &&
              data["cadre toute la largeur"] !== "Oui" &&
              axeBlockFrameJSX}
            </div>
          </div>
          <div
              className={`col-lg-6 col-md-12${
                  data["position images"] === "Gauche" ? " order-first" : ""
              }`}
          >
            <div className="axe-block-container">
              {data["image 1"] && (
                  <div className="axe-block-image-container">
                    <AbstractMedia
                        abstractMedia={data["image 1"]}
                        media={media}
                        className="axe-block-image"
                    />
                    {data["legende 1"] && data["legende 1"] !== "" && (
                        <span className="axe-block-legend">{data["legende 1"]}</span>
                    )}
                  </div>
              )}
              {data["image 2"] && (
                  <div className="axe-block-image-container">
                    <AbstractMedia
                        abstractMedia={data["image 2"]}
                        media={media}
                        className="axe-block-image"
                    />
                    {data["legende 2"] && data["legende 2"] !== "" && (
                        <span className="axe-block-legend">{data["legende 2"]}</span>
                    )}
                  </div>
              )}
              {data["image 3"] && (
                  <div className="axe-block-image-container">
                    <AbstractMedia
                        abstractMedia={data["image 3"]}
                        media={media}
                        className="axe-block-image"
                    />
                    {data["legende 3"] && data["legende 3"] !== "" && (
                        <span className="axe-block-legend">{data["legende 3"]}</span>
                    )}
                  </div>
              )}
              {data["position cadre"] &&
              data["position cadre"] !== "" &&
              data["position cadre"] === data["position images"] &&
              data["cadre toute la largeur"] !== "Oui" &&
              axeBlockFrameJSX}
            </div>
          </div>
        </div>
      </div>
  )
}

export default AxeBlock
